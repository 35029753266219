<template>
  <!-- This is re-used and thus global -->
  <ConfirmPopup></ConfirmPopup>
  <Toast position="top-center" />

  <div class="login-container" v-show="!busy && !user">
    <form @submit="login(loginForm.username, loginForm.password)" @submit.prevent>
      <h1>OpenVPN Login</h1>
      <div class="p-fluid">
        <div class="p-field">
          <label for="usernameInput">Username</label>
          <InputText id="usernameInput" ref="usernameInput" type="text" v-model="loginForm.username" autofocus/>
        </div>
        <div class="p-field">
          <label for="passwordInput">Password</label>
          <Password id="passwordInput" ref="passwordInput" :feedback="false" v-model="loginForm.password" :class="{ 'p-invalid': loginForm.error }"/>
          <small class="p-invalid" v-show="loginForm.error">Wrong username or password.</small>
        </div>
      </div>
      <Button type="submit" id="loginButton" :icon="loginForm.busy ? 'pi pi-spin pi-spinner' : ''" label="Login" :disabled="!loginForm.username || !loginForm.password || loginForm.busy" @click="login(loginForm.username, loginForm.password)"/>
    </form>
  </div>

  <div class="main-container" v-show="user">
    <div class="main-container-toolbar">
      <Toolbar>
        <template #start>
          <b>Cloudron OpenVPN</b>
        </template>
        <template #end>
          <Button label="New Device" id="newDeviceButton" class="p-ml-2 p-button-sm p-button-success" icon="pi pi-plus" @click="createKeyAsk()"/>
          <Button label="Refresh" class="p-ml-2 p-button-sm" icon="pi pi-refresh" @click="refresh()"/>
          <Button label="Settings" class="p-ml-2 p-button-sm" v-show="user && user.isAdmin" icon="pi pi-cog" @click="onSettingsOpen()"/>
          <Button label="Logout" id="logoutButton" class="p-ml-2 p-button-sm p-button-outlined" icon="pi pi-sign-out" @click="logout()"/>
        </template>
      </Toolbar>
    </div>
    <div class="main-container-body">
      <div class="main-container-content">
        <h3>Connected Clients: {{ connectedClientCount() }}</h3>

        <Button label="Add first Device" style="max-width: 200px" class="p-button-sm p-button-success" icon="pi pi-plus" @click="createKeyAsk()" v-show="!entries.length"/>

        <DataTable :value="entries" :autoLayout="true" v-show="entries.length">
          <Column field="connected" header="">
            <template #body="slotProps">
              <span class="status-led" style="background-color: #689f38;" v-show="slotProps.data.connected" v-tooltip.top="'Connected'"></span>
              <span class="status-led" style="background-color: #c4c4c4;" v-show="!slotProps.data.connected" v-tooltip.top="'Disconnected'"></span>
            </template>
          </Column>
          <Column field="name" header="Device Name"></Column>
          <Column field="created" header="Created">
            <template #body="slotProps">
              {{ prettyDate(slotProps.data.created) }}
            </template>
          </Column>
          <Column field="connected.vpnIp" header="VPN IP"></Column>
          <Column field="connected.remoteIp" header="Remote IP"></Column>
          <Column header="Actions" :style="'text-align: right;'">
            <template #body="slotProps">
              <Button label="Revoke" class="p-button-danger p-button-sm" @click="revokeKeyAsk(slotProps.data.name, $event)"/>
              <Button label="Download" icon="pi pi-chevron-down" iconPos="right" class="p-ml-2 p-button-sm p-button-outlined" @click="toggleDownloadMenu(slotProps.data.name, $event)"/>
            </template>
          </Column>
        </DataTable>

        <!-- dynamic download menu -->
        <Menu ref="menu" :model="mainMenu" :popup="true"/>

      </div>
    </div>
  </div>

  <!-- New Device Dialog -->
  <Dialog header="New Device Name" v-model:visible="newDevice.visible" :dismissableMask="true" :closable="true" :style="{width: '400px'}" :modal="true">
    <form @submit="onNewDeviceAdd()" @submit.prevent>
      <div class="p-fluid p-formgrid p-grid">
        <div class="p-field p-col-12">
          <InputText id="newDeviceNameInput" type="text" v-model="newDevice.name" autofocus required :class="{ 'p-invalid': newDevice.error }"/>
          <small class="p-invalid" v-show="newDevice.error">{{ newDevice.error }}</small>
        </div>
      </div>
    </form>
    <template #footer>
      <Button label="Cancel" icon="pi pi-times" class="p-button-text" @click="newDevice.visible = false"/>
      <Button label="Add" id="newDeviceSubmitButton" :icon="newDevice.busy ? 'pi pi-spin pi-spinner' : 'pi pi-check'" :disabled="!newDevice.name || settings.busy" class="p-button-text p-button-success" @click="onNewDeviceAdd()"/>
    </template>
  </Dialog>

  <!-- Settings Dialog -->
  <Dialog header="Settings" v-model:visible="settings.visible" :dismissableMask="true" :closable="true" :style="{width: '400px'}" :modal="true">
    <form @submit="onSettingsSave()" @submit.prevent>
      <div class="p-fluid p-formgrid p-grid">
        <small class="p-invalid" v-show="settings.error">{{ settings.error }}</small>
        <div class="p-field p-col-12">
          <label for="networkAddressInput">Network Address</label>
          <InputText id="networkAddressInput" type="text" v-model="settings.data.address" autofocus required :class="{ 'p-invalid': settings.errorAddress }"/>
          <small>Address Space: {{ settings.data.address }} / 24</small>
          <small class="p-invalid" v-show="settings.errorAddress">Must be a valid IPv4 address</small>
        </div>
      </div>
      <div class="p-fluid p-formgrid p-grid">
        <div class="p-field p-col-12">
          <label for="dnsServerInput">DNS Server</label>
          <InputText id="dnsServerInput" type="text" v-model="settings.data.dnsServer" :class="{ 'p-invalid': settings.errorDnsServer }"/>
          <small class="p-invalid" v-show="settings.errorDnsServer">Must be a valid IPv4 address</small>
        </div>
      </div>
      <div class="p-field-checkbox">
        <Checkbox id="allowICCCheckbox" v-model="settings.data.allowICC" :binary="true"/>
        <label for="allowICCCheckbox" style="font-weight: bold; font-size: 16.38px;">Client-to-client Connections</label>
      </div>
    </form>
    <template #footer>
      <Button label="Cancel" icon="pi pi-times" class="p-button-text" @click="settings.visible = false"/>
      <Button label="Save" :icon="settings.busy ? 'pi pi-spin pi-spinner' : 'pi pi-check'" :disabled="!settings.data.address || settings.busy" class="p-button-text p-button-success" @click="onSettingsSave()"/>
    </template>
  </Dialog>

</template>

<script>

import superagent from 'superagent';

function ipv4Validator(value) {
    return !!value.match(/^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/);
}

export default {
    name: 'Index',
    data() {
        return {
            busy: true,
            entries: [],
            user: null,
            loginForm: {
                busy: false,
                error: null,
                username: '',
                password: ''
            },
            connectedClients: {},
            newDevice: {
                busy: false,
                visible: false,
                error: null,
                name: ''
            },
            settings: {
                busy: false,
                visible: false,
                error: null,
                errorAddress: false,
                errorDnsServer: false,
                data: {
                    address: '',
                    netmask: '',
                    dnsServer: '',
                    allowICC: false
                }
            },
            mainMenu: [{
                label: '.ovpn',
                urlTemplate: '/api/key/DEVICENAME?format=ovpn&zip=false'
            }, {
                label: '.tblk',
                urlTemplate: '/api/key/DEVICENAME?format=tblk'
            }]
        };
    },
    methods: {
        toggleDownloadMenu: function (name, event) {
            this.mainMenu.forEach(function (item) {
                item.url = item.urlTemplate.replace('DEVICENAME', name);
            });

            this.$refs.menu.toggle(event);
        },
        connectedClientCount () {
            return this.entries.filter(e => e.connected).length;
        },
        logout () {
            superagent.get('/api/logout').end((error/*, result*/) => {
                if (error) return console.error(error);

                this.user = null;
                this.connectedClients = {};
                this.entries = [];
            });
        },
        login (username, password) {
            if (!username || !password) return;

            this.loginForm.busy = true;

            superagent.post('/api/login', { username: username, password: password }).end((error, result) => {
                this.loginForm.busy = false;
                this.loginForm.password = '';

                if (result && result.statusCode === 401) return this.loginForm.error = true;
                if (error) return console.error(error);

                this.loginForm.username = '';
                this.user = result.body.user;

                this.refresh();
            });
        },
        refresh () {
            this.busy = true;

            superagent.get('/api/list/')
                .end((error, result) => {
                    this.busy = false;

                    if (result && result.statusCode === 401) return this.logout();
                    if (error) return console.error(error);

                    this.entries = result.body.entries;

                    if (result.body.connectedClients) this.connectedClients = result.body.connectedClients;
                });
        },
        createKeyAsk () {
            this.newDevice.name = '';
            this.newDevice.error = null;
            this.newDevice.visible = true;
            this.newDevice.busy = false;
        },
        onNewDeviceAdd () {
            var that = this;

            this.newDevice.busy = true;

            function onError(error) {
                console.error(error);
                that.newDevice.error = error;
            }

            superagent.put('/api/key/' + this.newDevice.name).end((error, result) => {
                this.newDevice.busy = false;

                if (result && result.statusCode === 401) return this.logout();
                if (result && result.statusCode === 409) return onError(result.body.message);
                if (result && result.statusCode !== 201) return onError(result.body.message);
                if (error) return onError(error);

                this.refresh();

                this.newDevice.visible = false;
            });
        },
        revokeKeyAsk (name, event) {
            var that = this;

            this.$confirm.require({
                target: event.currentTarget,
                message: 'Really revoke keys?',
                icon: 'pi pi-info-circle',
                acceptClass: 'p-button-danger',
                accept: () => {
                    const onError = error => {
                        console.error(error);
                        that.$toast.add({ severity:'error', summary: 'Failed to revoke keys', detail: error, life: 3000 });
                    };

                    superagent.delete('/api/key/' + name).end((error, result) => {
                        if (result && result.statusCode === 401) return this.logout();
                        if (result && result.statusCode === 409) return onError(result.body.message);
                        if (result && result.statusCode !== 200) return onError(result.body.message);
                        if (error) return onError(error);

                        that.refresh();
                    });
                },
                reject: () => {}
            });
        },
        prettyDate (timestamp) {
            const d = new Date(timestamp);
            return d.toDateString();
        },
        refreshSettings () {
            superagent.get('/api/settings').end((error, result) => {
                this.busy = false;

                if (result && result.statusCode === 401) return this.logout();
                if (error) return console.error(error);

                this.settings.data = result.body.settings;
            });
        },
        onSettingsSave () {
            this.settings.errorAddress = false;
            this.settings.errorDnsServer = false;
            this.settings.error = null;

            if (!ipv4Validator(this.settings.data.address)) return this.settings.errorAddress = true;
            if (!ipv4Validator(this.settings.data.dnsServer)) return  this.settings.errorDnsServer = true;

            this.settings.busy = true;

            // hardcode netmask for now
            this.settings.data.netmask = '255.255.255.0';

            superagent.post('/api/settings', { settings: this.settings.data }).end((error, result) => {
                this.settings.busy = false;

                if (result && result.statusCode === 401) return this.logout();
                if (result && result.statusCode === 403) return this.settings.error = result.body.message;
                if (error) return console.error(error);

                this.onSettingsClose();
            });
        },
        onSettingsClose () {
            this.settings.visible = false;
        },
        onSettingsOpen () {
            this.settings.visible = true;
            this.settings.error = null;
            this.settings.errorAddress = false;
            this.settings.errorDnsServer = false;

            this.refreshSettings();
        }
    },
    mounted() {
        this.busy = true;

        superagent.get('/api/profile').end((error, result) => {
            this.busy = false;

            if (result && result.statusCode === 401) return;
            if (error) return console.error(error);

            this.user = result.body.user;

            this.refresh();
        });
    }
};

</script>

<style>

.login-container {
    max-width: 480px;
    margin: auto;
    padding: 20px;
}

hr {
    border: none;
    border-top: 1px solid #d0d0d0;
}

label {
    font-weight: bold;
}

.p-toast {
    z-index: 2000 !important;
}

.p-menu {
    width: 200px;
}

.status-led {
    display: block;
    width: 16px;
    height: 16px;
    border-radius: 8px;
    background-color: black;
}

.p-datatable .p-datatable-thead > tr > th {
    background: white;
}

</style>
